footer {
    > div {
        &:nth-child(1) {
            display: flex;
            justify-content: center;
            padding: 60px 0;
            background: url("../images/index/footer/footerbg.png") no-repeat
                center;
            background-size: cover;
            @include media($md) {
                padding: 66px 16px 30px;
            }
            @include media($sm) {
                flex-direction: column;
            }
        }
        // TODO:: COPYRIGHT
        &:nth-child(2) {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $black;
            min-height: 72px;
            color: $darken-grey;
            @include media($sm) {
                min-height: 84px;
            }
            p {
                text-align: center;
                @include media($sm) {
                    text-align: center;
                    line-height: 1.56;
                }
                a {
                    color: $white;
                    padding-left: 6px;
                    @include media($sm) {
                        text-decoration: underline;
                    }
                }
                span {
                    display: inline-block;
                    margin-left: 6px;
                    @include media($lg) {
                        display: block;
                        margin-top: 4px;
                        margin-left: 0;
                    }
                    @include media($sm) {
                        margin-top: 0;
                    }
                }
            }
        }
    }
    .componyInfo__container {
        margin-right: 156px;
        @include media($lg) {
            margin-right: 32px;
        }
        @include media($md) {
            margin-right: auto;
            margin-bottom: 66px;
        }
        img {
            margin-bottom: 12px;
        }
        p {
            color: $darken-grey;
            line-height: 1.56;
        }
        > div {
            display: none;
            @include media($md) {
                display: flex;
                margin-top: 32px;
            }
            a {
                display: block;
                color: $darken-grey;
                &.socialBtn {
                    display: flex;
                    align-items: center;
                    width: 110px;
                    height: 24px;
                    padding-right: 0;
                    line-height: 24px;
                    font-size: 18px;
                    color: $white;
                    margin: 0;
                    text-decoration: none;
                    &.line {
                        background-color: $line-green;
                        padding-left: 4px;
                        margin-bottom: 12px;
                        margin-right: 20px;
                        img {
                            height: 14px;
                            margin: 0 4px 0;
                        }
                    }
                    &.facebook {
                        background-color: $facebook-blue;
                        padding-left: 8px;
                        img {
                            width: 7px;
                            margin: 0 8px 0;
                        }
                    }
                }
            }
        }
    }
    .relatedLink__container {
        display: flex;
        @include media($md) {
            flex: 0 0 200px;
            flex-wrap: wrap;
        }
        @include media($sm) {
            flex: auto;
        }
        > div {
            padding-top: 20px;
            margin-right: 66px;
            @include media($lg) {
                margin-right: 16px;
            }
            @include media($sm) {
                flex: 0 0 140px;
                max-width: 140px;
                padding-top: 0;
            }
            h3 {
                font-size: 20px;
                color: $white;
                margin-bottom: 36px;
                @include media($md) {
                    margin-bottom: 12px;
                }
                @include media($sm) {
                    margin-bottom: 40px;
                }
            }
            p {
                color: $white;
                margin-bottom: 20px;
                span {
                    color: $yellow;
                }
            }
            a {
                display: block;
                color: $darken-grey;
                margin-bottom: 20px;
                @include media($md) {
                    margin-bottom: 8px;
                }
                @include media($sm) {
                    margin-bottom: 24px;
                }
                &.mailto {
                    display: inline;
                    color: $yellow;
                    margin-bottom: 0;
                    @include media($md) {
                        display: inline-block;
                        margin-top: 4px;
                    }
                }
                &.socialBtn {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 150px;
                    height: 40px;
                    line-height: 40px;
                    font-size: 18px;
                    color: $white;
                    padding-right: 24px;
                    margin: 0;
                    text-decoration: none;
                    @include media($md) {
                        display: none;
                    }
                    @include media($sm) {
                        display: flex;
                    }
                    &.line {
                        background-color: $line-green;
                        padding-left: 12px;
                        margin-bottom: 12px;
                    }
                    &.facebook {
                        background-color: $facebook-blue;
                        padding-left: 18px;
                    }
                }
            }
            &:nth-child(3) {
                margin-right: 0;
                @include media($md) {
                    flex: 0 0 100%;
                    max-width: 100%;
                }
                @include media($sm) {
                    display: flex;
                    justify-content: space-between;
                    margin-top: 56px;
                }
                h3 {
                    @include media($sm) {
                        margin-bottom: 16px;
                    }
                }
            }
        }
    }
}

.d-none {
    display: none;
}
