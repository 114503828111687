$grid-breakpoints: (
  xl: 1199px,
  lg: 991px,
  md: 767px,
  sm: 575px,
  xs: 0px,
) !default;

$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge(
  (
    0: 0,
    1: $spacer * 0.25,
    2: $spacer * 0.5,
    3: $spacer,
    4: $spacer * 1.5,
    5: $spacer * 3,
  ),
  $spacers
);

$utilities: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$utilities: map-merge(
  (
    'flex': (
      responsive: true,
      property: flex,
      values: (
        fill: 1 1 auto,
      ),
    ),
    'flex-direction': (
      responsive: true,
      property: flex-direction,
      class: f-d,
      values: row column row-reverse column-reverse,
    ),
    'flex-wrap': (
      responsive: true,
      property: flex-wrap,
      class: f-w,
      values: wrap nowrap wrap-reverse,
    ),
    'justify-content': (
      responsive: true,
      property: justify-content,
      class: f-jc,
      values: (
        start: flex-start,
        end: flex-end,
        center: center,
        between: space-between,
        around: space-around,
      ),
    ),
    'align-items': (
      responsive: true,
      property: align-items,
      class: f-ai,
      values: (
        start: flex-start,
        end: flex-end,
        center: center,
        baseline: baseline,
        stretch: stretch,
      ),
    ),
    'order': (
      responsive: true,
      property: order,
      values: (
        first: -1,
        0: 0,
        1: 1,
        2: 2,
        3: 3,
        4: 4,
        5: 5,
        last: 6,
      ),
    ),
    'position': (
      class: p,
      responsive: true,
      property: position,
      values: static relative absolute fixed sticky,
    ),
    'display': (
      responsive: true,
      print: true,
      property: display,
      class: dp,
      values: none inline inline-block block table table-row table-cell flex inline-flex,
    ),
    'overflow': (
      property: overflow,
      values: auto hidden,
    ),
    'margin': (
      responsive: true,
      property: margin,
      class: m,
      values:
        map-merge(
          $spacers,
          (
            auto: auto,
          )
        ),
    ),
    'margin-x': (
      responsive: true,
      property: margin-right margin-left,
      class: mx,
      values:
        map-merge(
          $spacers,
          (
            auto: auto,
          )
        ),
    ),
    'margin-y': (
      responsive: true,
      property: margin-top margin-bottom,
      class: my,
      values:
        map-merge(
          $spacers,
          (
            auto: auto,
          )
        ),
    ),
    'margin-top': (
      responsive: true,
      property: margin-top,
      class: mt,
      values:
        map-merge(
          $spacers,
          (
            auto: auto,
          )
        ),
    ),
    'margin-right': (
      responsive: true,
      property: margin-right,
      class: mr,
      values:
        map-merge(
          $spacers,
          (
            auto: auto,
          )
        ),
    ),
    'margin-bottom': (
      responsive: true,
      property: margin-bottom,
      class: mb,
      values:
        map-merge(
          $spacers,
          (
            auto: auto,
          )
        ),
    ),
    'margin-left': (
      responsive: true,
      property: margin-left,
      class: ml,
      values:
        map-merge(
          $spacers,
          (
            auto: auto,
          )
        ),
    ),
    'padding': (
      responsive: true,
      property: padding,
      class: p,
      values: $spacers,
    ),
    'padding-x': (
      responsive: true,
      property: padding-right padding-left,
      class: px,
      values: $spacers,
    ),
    'padding-y': (
      responsive: true,
      property: padding-top padding-bottom,
      class: py,
      values: $spacers,
    ),
    'padding-top': (
      responsive: true,
      property: padding-top,
      class: pt,
      values: $spacers,
    ),
    'padding-right': (
      responsive: true,
      property: padding-right,
      class: pr,
      values: $spacers,
    ),
    'padding-bottom': (
      responsive: true,
      property: padding-bottom,
      class: pb,
      values: $spacers,
    ),
    'padding-left': (
      responsive: true,
      property: padding-left,
      class: pl,
      values: $spacers,
    ),
    'text-align': (
      responsive: true,
      property: text-align,
      class: text,
      values: left right center justify,
    ),
  ),
  $utilities
);
@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  @if $min {
    @media (max-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}
@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
  $min: map-get($breakpoints, $name);
  @return if($min != 0, $min, null);
}
@mixin generate-utility($utility, $infix) {
  $values: map-get($utility, values);
  @if type-of($values) == 'string' or type-of(nth($values, 1)) != 'list' {
    $values: zip($values, $values);
  }
  @each $value in $values {
    $properties: map-get($utility, property);
    @if type-of($properties) == 'string' {
      $properties: append((), $properties);
    }
    $property-class: map-get($utility, class);
    $property-class: if($property-class, $property-class, nth($properties, 1));
    $property-class-modifier: if(nth($value, 1), '-' + nth($value, 1), '');

    .#{$property-class + $infix + $property-class-modifier} {
      @each $property in $properties {
        #{$property}: #{nth($value, 2)} !important;
      }
    }
  }
}
@function breakpoint-infix($name, $breakpoints: $grid-breakpoints) {
  @return if(breakpoint-min($name, $breakpoints) == null, '', '-#{$name}');
}
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    @each $key, $utility in $utilities {
      @if type-of($utility) == 'map' and (map-get($utility, responsive) or $infix == '') {
        @include generate-utility($utility, $infix);
      }
    }
  }
}
