$tealish: #26aea1;
$blueberry: #4034a0;
$brownish-grey: #707070;
$white: #ffffff;
$cornflower: #6c63ff;
$lighten-grey: #666;
$grey: #444;
$green: #1abc9c;
$black: #0b0b0c;
$darken-grey: #9d9d9d;
$facebook-blue: #3b5992;
$line-green: #00b900;
$yellow: #ffb92d;
$darken-green: #07bfa5;
$darken-grey: #6f6f6f;
$darken-grey-two: #333;
$lighten-grey: #e3e3e3;
$purple: #4534a0;
$blue: #2a64cc;
