*,
*::before,
*::after {
    box-sizing: border-box;
}

a {
    display: inline-block;
    text-decoration: none;
}
