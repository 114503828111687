@mixin circle($size: 25px,$bgcolor: black) {
    display: inline-block;
    width: $size;
    height: $size;
    border-radius: 50%;
    background: $bgcolor;
}

@mixin bgSetting($url) {
    background: {
        image: url("#{$imgPath}#{$url}");
        position: center;
        repeat: no-repeat;
        size: cover;
    }
}

@mixin parallax($url) {
    background: {
        image: url("#{$url}");
        position: center;
        repeat: no-repeat;
        size: cover;
        color: transparent;
        attachment: fixed;
    }
}

@mixin triangle($color: black,$heightSize: 5px, $widthSize: 10px) {
    @extend %triangle;
    border-width: $heightSize $widthSize 0 $widthSize;
    border-color: $color transparent transparent transparent transparent;
}

@mixin linear($deg: 90deg, $firstColor: black, $secondColor: white) {
    background: linear-gradient($deg, $firstColor 0%, $secondColor 100%)
}

@mixin font($index:1) {
    font-size: nth($font, $index );
    line-height: line-height($index);

    @if $index < 1 {
        $index: 1;
    }

    @if $index > length($font) {
        $index: length($font);
    }
}

@mixin line($lineNum: 2) {
    overflow:hidden;
    text-overflow:ellipsis;
    line-clamp: $lineNum
}

@mixin shadow($out: 5px, $in: 5px) {
    box-shadow: 0 0 $out rgba(0, 0, 0, .3), inset 0 0 $in rgba(0, 0, 0, .5);
}

@mixin media ($query: $mobile) {
    @media #{$query} {
        @content
    }
}